<template>
  <span>{{ numberFormatter }}</span>
</template>

<script>

export default {
  name: 'NumberFormatter',
  props: {
    decimals: {
      type: Number,
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
  },
  computed: {
    numberFormatter() {
      const value = this.value || 0;
      return value.toLocaleString(
        undefined,
        {
          minimumFractionDigits: this.decimals,
          maximumFractionDigits: this.decimals,
        },
      );
    },
  },
};
</script>
