<template>
  <div class="demo-vertical-spacing">
    <vue-c3
      :handler="handler"
      style="height: 250px"
    />
  </div>
</template>

<script>
import Vue from 'vue'; // eslint-disable-line import/no-extraneous-dependencies
import VueC3 from 'vue-c3/src';

export default {
  name: 'Charts',

  components: {
    VueC3,
  },

  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      handler: new Vue(),
    };
  },

  mounted() {
    this.handler.$emit('init', this.options);
  },

  beforeDestroy() {
    this.handler.$emit('destroy');
  },
};
</script>

<style src="~/vendor/libs/c3/c3.scss" lang="scss" />
